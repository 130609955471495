import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationContextService } from 'src/app/core/services/application-context.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-user-companies-list',
  templateUrl: './user-companies-list.component.html',
  styleUrls: ['./user-companies-list.component.scss']
})
export class UserCompaniesListComponent implements OnInit {
  userCompanies: any = [];
  filteredCompanies: any = [];
  isLoading: boolean = false;
  returnUrl: string = '';
  currentCompanyName: string;

  constructor(private readonly _router: Router,
    private readonly _appContextService: ApplicationContextService,
    private readonly _authService: AuthenticationService) {
  }

  ngOnInit() {
    this._appContextService.context.subscribe(context => {
      this.currentCompanyName = context.currentlyLoggedInUser.companyName;
      this.userCompanies = context.userCompanies
        .filter(res => res.isHidden !== true)
        .sort((a, b) => Number(b.isPinned) - Number(a.isPinned));
      this.filteredCompanies = this.userCompanies.filter(uc => uc.companyId !== context.currentlyLoggedInUser.companyId);
    });
  }

  filterCompanyByName(evt: any) {
    const searchString = (evt.target as HTMLInputElement).value;
    this.filteredCompanies = this.userCompanies.filter((uc: any) => !searchString || uc.company.name.toLowerCase().includes(searchString.toLowerCase()))
  }

  companySelected(userId: string, selectedCompany: string) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this._authService.getAuthTokenForCompany(userId, selectedCompany).subscribe({
      next: (response) => {
        this._authService.persistAuthData(response);
        this._appContextService.resetContext();
        this._router.navigateByUrl('/');
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  pinOrHideCompany(selectedUserCompany: any) {
    const request = this.createRequestPayload(selectedUserCompany);
    this._authService.hideOrPinCompany(request).subscribe({
      next: (res) => {
        console.log(res)
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  onPinClick(selectedUserCompany: any) {
    selectedUserCompany.isPinned = !selectedUserCompany.isPinned;
    this.pinOrHideCompany(selectedUserCompany);
  }

  onHideClick(selectedUserCompany: any) {
    selectedUserCompany.isHidden = true;
    this.pinOrHideCompany(selectedUserCompany);
  }

  private createRequestPayload(selectedUserCompany: any) {
    return {
      userId: selectedUserCompany.userId,
      companyId: selectedUserCompany.company.id,
      isHidden: selectedUserCompany.isHidden,
      isPinned: selectedUserCompany.isPinned,
    }
  }

  returnToHome() {
    this._router.navigateByUrl('/');
  }
}
