import { Component, HostListener, OnInit } from '@angular/core';
import { GuardsCheckEnd, GuardsCheckStart, NavigationCancel, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    if (environment.production) {
      event.preventDefault();
    }
  }
  isLoading: boolean;
  constructor(private readonly _router: Router) {
  }
  ngOnInit() {
    this._router.events.subscribe(event => {
      if (event instanceof GuardsCheckStart) {
        this.isLoading = true;
      }
      if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
        this.isLoading = false;
      }
    });
  }
}
