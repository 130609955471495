import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable, catchError, map, of } from 'rxjs';
import { EnumerationItem, PatientSearchResult, UserTypeLookup } from '../models/lookup.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(private readonly _dataService: DataService) {
  }

  getUserTypes(): Observable<UserTypeLookup[]> {
    return this._dataService.get('api/v1/types');
  }

  searchPatientByPartialName(searchTerm: string): Observable<PatientSearchResult[]> {
    if (!searchTerm) {
      return of(null);
    }
    const url = `${environment.phiBaseUrl}api/v1/patients/search/term?term=${encodeURIComponent(searchTerm)}`;
    return this._dataService.get(url, {}, false);
  }
}
