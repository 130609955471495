<div id="apps-list" class="apps-container">
    <img id="close-tools-panel" src="assets/images/custom/close-all-tools.svg" role="button"
        (click)="appsPanelClosed.emit()">
    <h4 class="mt-1 text-dark">All Tools</h4>
    <div class="d-flex flex-wrap">
        <a (click)="appClicked('charts',availableLicenses.includes('charts'))" class="app-item"
            [ngClass]="{'disabled': !availableLicenses.includes('charts')}">
            <div class="app-logo">
                <img src="assets/images/custom/charts.svg" alt="charts app image" />
            </div>
            <span class="fs-6 fw-bold">Charts</span>
        </a>
        <a (click)="appClicked('biller',availableLicenses.includes('biller'))" class="app-item"
            [ngClass]="{'disabled': !availableLicenses.includes('biller')}">
            <div class="app-logo">
                <img id="biller-logo" src="assets/images/custom/biller.svg" alt="biller app image" />
            </div>
            <span class="fs-6 fw-bold">Biller</span>
        </a>
        <a (click)="appClicked('phi', true)" class="app-item">
            <div class="app-logo">
                <img src="assets/images/custom/patients.svg" alt="patient image" />
            </div>
            <span class="fs-6 fw-bold">Patients</span>
        </a>
        <a (click)="appClicked('studies', availableLicenses.includes('studies'))" class="app-item"
            [ngClass]="{'disabled': !availableLicenses.includes('studies')}">
            <div class="app-logo">
                <img id="studies-logo" src="assets/images/custom/studies.svg" alt="studies app image" />
            </div>
            <span class="fs-6 fw-bold">Studies</span>
        </a>
        <a *ngIf="availableLicenses.includes('vascunote')"
            (click)="appClicked('vascunote', availableLicenses.includes('vascunote'))" class="app-item">
            <div class="app-logo">
                <img id="studies-logo" src="assets/images/custom/vascunote.png" alt="vascunote app image" />
            </div>
            <span class="fs-6 fw-bold">Vascunote</span>
        </a>
    </div>
</div>