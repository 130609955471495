import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';
import { LocalStorageService } from './local-storage.service';

const headers = {
  'Content-Type': 'application/json',
  'auth-req': 'true',
  'X-Requested-With': 'XMLHttpRequest',
};

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private readonly _http: HttpClient,
    private readonly _localStorageService: LocalStorageService) {
  }

  post = (url: string, data: any): Observable<any> => {
    this.appendDeviceId();
    const urlToUse = this.apiBaseUrl + url;
    this.addHmacSignature(data);
    return this._http.post(urlToUse, data, { headers });
  };

  put = (url: string, data: any): Observable<any> => {
    this.appendDeviceId();
    const urlToUse = this.apiBaseUrl + url;
    return this._http.put(urlToUse, data, { headers: headers });
  };

  patch = (url: string, data: any): Observable<any> => {
    this.appendDeviceId();
    const urlToUse = this.apiBaseUrl + url;
    return this._http.patch(urlToUse, data, { headers: headers });
  }

  get = (url: string, customOptions?: any, appendBaseUrl: boolean = true): Observable<any> => {
    this.appendDeviceId();
    const urlToUse = appendBaseUrl ? this.apiBaseUrl + url : url;
    return this._http.get(urlToUse, { headers: headers, ...customOptions });
  };

  delete = (url: string, data?: any): Observable<any> => {
    this.appendDeviceId();
    const urlToUse = this.apiBaseUrl + url;
    return this._http.delete(urlToUse, { headers: headers, body: data });
  };

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }

  private addHmacSignature(data: any) {
    const payload = data
      ? JSON.stringify(data)
      : 'NO_PAYLOAD';
    const signature = crypto.HmacSHA256(payload, environment.signatureKey).toString(crypto.enc.Hex);
    headers['signature'] = signature;
  }

  private appendDeviceId() {
    if (this._localStorageService.machineId) {
      headers['user_deviceid'] = this._localStorageService.machineId;
    }
  }
}
