import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { CompanyProductLicense } from '../models/company.model';
import { Observable } from 'rxjs';
import { Pacs } from '../models/pacs.model';
import { EditableCompanyInfo } from 'src/app/pages/users/user.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private readonly _dataService: DataService) { }

  getCompanyProductsLicenses(): Observable<CompanyProductLicense[]> {
    return this._dataService.get('api/v1/companies/company_licenses');
  }

  getCompanyPacsInfo(): Observable<Pacs> {
    return this._dataService.get('api/v1/companies/pac_info');
  }

  savePacsInfo(request: Pacs) {
    return this._dataService.post('api/v1/companies', request);
  }

  updateCompanyInformation(companyId: string, company: EditableCompanyInfo) {
    return this._dataService.patch(`api/v1/companies/${companyId}`, company);
  }
}
