import { IEnvironment } from "src/app/core/models/environment.interface";

export const environment: IEnvironment = {
  production: false,
  loginUrl: 'https://dev-app.healthexam.com',
  apiBaseUrl: 'https://as-account-v2-d-90167a8f915a.herokuapp.com/',
  phiBaseUrl: 'https://as-phi-v2-d-e036136e9efe.herokuapp.com/',
  remitAppUrl: 'https://wa-remit-v2-d-f32c1665b95c.herokuapp.com',
  adminAppUrl: 'https://dev-admin.healthexam.com',
  phiAppUrl: 'https://dev-patients.healthexam.com',
  studiesAppUrl: 'https://dev-studies.healthexam.com',
  billerAppUrl: 'https://dev-biller.healthexam.com',
  clinicAppUrl: 'https://dev-clinic.healthexam.com',
  chartsAppUrl: 'https://dev-charts.healthexam.com',
  signatureKey: 'heathexamplatform@lo!@0oa8zj*4j8y=tx*6t$ytx-4=nqne8pbi8_tiv9l(8jaf('
}
