import { Injectable } from '@angular/core';
import { ApplicationContext } from '../models/application-context.model';
import { Observable, Subject, catchError, map, of, switchMap } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { EditableCompanyInfo, User, UserCompany } from 'src/app/pages/users/user.model';
import { CompanyService } from './company.service';
import { CompanyProductLicense } from '../models/company.model';
import { merge } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ApplicationContextService {
  private _applicationContext: ApplicationContext;
  private _contextChangedSubject: Subject<ApplicationContext> = new Subject<ApplicationContext>();

  constructor(private readonly _userService: UserService,
    private readonly _companyService: CompanyService) { }

  get context(): Observable<ApplicationContext> {
    if (this._applicationContext) {
      return of(this._applicationContext);
    }
    return this.initializeApplicationContext().pipe(map(context => {
      this._applicationContext = context;
      this._contextChangedSubject.next(context);
      return context;
    }));
  }

  get contextChanged$(): Subject<ApplicationContext> {
    return this._contextChangedSubject;
  }

  updateCompanyInformation(companyId: string, updatedCompanyInfo: EditableCompanyInfo) {
    this._applicationContext.currentlyLoggedInUser.companyName = updatedCompanyInfo.name;
    const matchingCompany = this._applicationContext.userCompanies.find(c => c.companyId === companyId);
    if (!matchingCompany) {
      return;
    }
    merge(matchingCompany.company, updatedCompanyInfo);
    this._contextChangedSubject.next(this._applicationContext);
  }

  resetContext() {
    this._applicationContext = null;
  }

  private initializeApplicationContext = (): Observable<ApplicationContext> => {
    return this._userService.getLoggedInUser().pipe(
      catchError((error) => of(error)),
      switchMap((user: User) => this._userService.getUserCompanies(user.id).pipe(
        catchError((error) => of(error)),
        switchMap((userCompanies: UserCompany[]) => this._companyService.getCompanyProductsLicenses().pipe(
          map((licenses: CompanyProductLicense[]) => ({
            currentlyLoggedInUser: user,
            userCompanies,
            companyLicenses: licenses.map(l => l.name)
          })
          )))
      ))
    );
  }
}
