<div class="overlay">
    <div *ngIf="!isLoading else loader" class="container text-center">
        <img id="logo-img" src="assets/images/custom/logo-healthexam.svg" alt="logo health exam" />
        <div class="company-panel">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <h4 class="modal-title pull-left">Switch Company</h4>
                <button type="button" class="btn-close close pull-right" aria-label="Close"
                    (click)="returnToHome()"></button>
            </div>
            <div class="current-company-panel">
                Currently Signed in to: <strong>{{currentCompanyName}}</strong>
            </div>
            <div class="d-flex flex-wrap flex-column company-list">
                <div role="button" (click)="companySelected(uc.userId, uc.company.id)"
                    class="d-flex justify-content-between align-items-center company-btn"
                    *ngFor="let uc of filteredCompanies">
                    <div class="me-3">{{uc.company.name}}</div>
                    <div (click)="$event.stopPropagation();" class="btn-group" dropdown container="body">
                        <i class="fa-light fa-ellipsis-vertical dropdown-toggle fs-4" role="button" dropdownToggle
                            data-toggle="dropdown" aria-expanded="true"></i>
                        <div class="dropdown-menu dropdown-menu-end company-menu" *dropdownMenu>
                            <span class="dropdown-item" role="button" (click)="onPinClick(uc)">
                                <i class="fa-solid fa-thumbtack text-secondary"></i>
                                <span class="ms-2">{{uc.isPinned ? 'Unpin from top' : 'Pin to top' }}</span>
                            </span>
                            <span class="dropdown-item" role="button" (click)="onHideClick(uc)">
                                <i class="fa-duotone fa-eye"></i>
                                <span class="ms-2">Hide</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngIf="!filteredCompanies?.length">
                    <em>No company available.</em>
                </div>
            </div>
        </div>
    </div>
    <ng-template #loader>
        <app-loader></app-loader>
    </ng-template>
</div>