import { Component, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'session-timeout',
  template: ''
})
export class SessionTimeoutComponent implements OnInit {
  idleTimeInMins = 10;
  idleTimeoutInMins = 5;

  constructor(private idle: Idle,
    private readonly _authService: AuthenticationService,
    private readonly _toastService: ToastrService) {
    idle.setIdle(this.idleTimeInMins * 60);
    idle.setTimeout(this.idleTimeoutInMins * 60);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onTimeout.subscribe(() => {
      this._authService.logout();
    });
    idle.onIdleStart.subscribe(() => {
      this.idle.stop();
      let timerInterval;
      Swal.fire({
        title: 'Are you still there?',
        html: `You will be logged out in <b>${this.idleTimeoutInMins} mins</b>!`,
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#513DCA',
        confirmButtonText: 'No',
        cancelButtonText: 'Yes',
        reverseButtons: true,
        backdrop: 'rgb(230 230 230 / 96%)',
        timer: (this.idleTimeoutInMins * 60000),
        didOpen: () => {
          const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = this.secondsToMinsSecsText(Swal.getTimerLeft());
          }, 1000);
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this._authService.logout();
        } else if (result.dismiss === Swal.DismissReason.timer) {
          this.showResumeSessionModal();
        } else {
          this.idle.watch();
        }
      });
    });
  }

  private showResumeSessionModal() {
    Swal.fire({
      title: 'Session Expired',
      html: `<p class="fw-bold">You were logged out due to inactivity.</p>
      <div id="session-timeout-content">
        <div class="mt-2">
          <button type="button" class="btn-resume-session swal2-confirm swal2-styled swal2-default-outline"
          style="display: inline-block; background-color: #486c6a;">
            Resume
          </button> 
        </div>
      </div>
      <div id="session-timeout-loader" style="display:none">
        <img src="/assets/images/custom/loader.gif" width="70px" role="status" />
        <small style="display:block">Resuming Session...</small>
      </div>`,
      icon: 'warning',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      backdrop: 'rgb(230 230 230 / 96%)',
      willOpen: () => {
        const resumeSession = document.querySelector('.btn-resume-session');

        resumeSession.addEventListener('click', () => {
          this.checkDeviceSession();
        });
      }
    })
  }

  ngOnInit(): void {
    this.idle.watch();
  }

  checkDeviceSession() {
    const content: HTMLElement = document.getElementById('session-timeout-content');
    const loader: HTMLElement = document.getElementById('session-timeout-loader');

    content.style.display = 'none';
    loader.style.display = 'block';
    this._authService.deviceSessionCheck().subscribe({
      next: (res) => {
        if (!res) {
          this._authService.logout();
          return;
        }
        this._authService.persistAuthData(res);
        Swal.close();
        this.idle.watch();
      }, error: (err) => {
        this._toastService.error(err || 'unable to get user session info.', 'Error!');
      }
    }).add(() => {
      content.style.display = 'block';
      loader.style.display = 'none';
    });
  }

  private secondsToMinsSecsText = (milliseconds: number): string => {
    const seconds = milliseconds / 1000;
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 3600 % 60);
    const mDisplay = m > 0 ? m + (m == 1 ? " min" : " mins") + (s > 0 ? ", " : "") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
    return mDisplay + sDisplay;
  }
}