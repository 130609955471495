<div class="modal-header">
    <h4 class="modal-title pull-left">Search</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <input #searchField type="text" class="form-control mb-2" (input)="onUserInput($event)"
      placeholder="Search patients by Last Name or DOB" />
    <ng-container *ngIf="patientsList$ | async as patientsList">
      <p-table *ngIf="!loadingPatients" #patientSearchTable [value]="patientsList" [paginator]="true" [rows]="10"
        [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
        <ng-template pTemplate="header">
          <tr>
            <th>Name</th>
            <th>DOB</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-patient>
          <tr role="button" (click)="openPatientDetails(patient.id)">
            <td>{{patient.name | titlecase}}</td>
            <td>{{patient.dob | dateWithoutTimezone : "MM/dd/yyyy"}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate='emptymessage'>
          <tr>
            <td colspan="2" class='text-center'>
              <i class="fa-duotone fa-triangle-exclamation fs-4"></i>
              <span>We could not find any patient matching your search criteria. please
                try again</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-container>
    <app-loader *ngIf="loadingPatients" [allowFullHeight]="false"></app-loader>
    <div class="patient-search-image-container" *ngIf="!loadingPatients && !hasSearchTerm(searchField)"></div>
  </div>