<div class="left-menu" (mouseenter)="expandSidebarWithDebouce(true)" (mouseleave)="expandSidebarWithDebouce(false)" 
[class]="expandSidebar ? 'hover' : ''">
  <ngx-simplebar class="h-100" #componentRef>
    <div class="h-100 sidebar-menu">
      <div class="h-100 d-flex flex-column justify-content-between align-items-left">
        <ul>
          <li class="logo-menu-item">
            <a routerLink="/">
            </a>
          </li>
          <ng-container *ngFor="let item of menuItems">
            <li>
              <a *ngIf="!item.hasExternalLink" class="menu-link" routerLink="{{item.link}}" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <img *ngIf="item.iconUrl" [src]="isCurrentRoute(item.link) ? item.activeIconUrl : item.iconUrl" alt=""
                  width="{{item.width}}">
                <span class="menu-label"> {{ item.label }}</span>
              </a>
              <a *ngIf="item.hasExternalLink" class="menu-link" (click)="menuItemClicked(item)" role="button">
                <img *ngIf="item.iconUrl" [src]="item.iconUrl" alt="" width="{{item.width}}">
                <span class="menu-label"> {{ item.label }}</span>
              </a>
            </li>
          </ng-container>
          <li>
            <a class="menu-link"  href="javascript:void(0)" (click)="showAllTools($event)">
              <img [src]="'assets/images/custom/sidebar/all-tools.svg'" alt="" width="18">
              <span class="menu-label"> All Tools </span>
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a class="menu-link" href="javascript:void(0)" (click)="openSeachModal()">
              <img [src]="'assets/images/custom/sidebar/search.svg'" alt="" width="18">
              <span class="menu-label"> Search </span>
            </a>
          </li>
          <li>
            <a class="menu-link" href="javascript:void(0)" (click)="openSettingsApp()">
              <img [src]="'assets/images/custom/sidebar/settings.svg'" alt="" width="18">
              <span class="menu-label"> Settings </span>
            </a>
          </li>
          <li>
            <a class="menu-link" href="https://support.healthexam.com/" target="_blank">
              <img [src]="'assets/images/custom/sidebar/help.svg'" alt="" width="18">
              <span class="menu-label"> Help </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </ngx-simplebar>
</div>
<div class="app-menu-logo" *ngIf="showAppsPanel">
  <app-list (appsPanelClosed)="showAppsPanel = false;"></app-list>
</div>