import { MenuItemsEnum } from 'src/app/core/services/utils';
import { MenuItem } from './menu.model';
export const MENU: MenuItem[] = [
    {
        id: MenuItemsEnum.Users,
        label: 'Users',
        iconUrl: 'assets/images/custom/sidebar/users.svg',
        activeIconUrl: 'assets/images/custom/sidebar/users-active.svg',
        link: '/',
        width: 18
    },
    {
        id: MenuItemsEnum.Locations,
        label: 'Facilities',
        iconUrl: 'assets/images/custom/sidebar/locations.svg',
        activeIconUrl: 'assets/images/custom/sidebar/locations-active.svg',
        link: '/facilities',
        width: 20
    },
    {
        id: MenuItemsEnum.Products,
        label: 'Products',
        iconUrl: 'assets/images/custom/sidebar/products.svg',
        activeIconUrl: 'assets/images/custom/sidebar/products-active.svg',
        link: '/products',
        width: 18
    },
    // {
    //     id: MenuItemsEnum.Portal,
    //     label: 'Portal',
    //     iconUrl: 'assets/images/custom/sidebar/portal.svg',
    //     activeIconUrl: 'assets/images/custom/sidebar/portal-active.svg',
    //     link: '/billing-portal',
    //     width: 18
    // },
    {
        id: MenuItemsEnum.PACS,
        label: 'PACS',
        iconUrl: 'assets/images/custom/sidebar/pacs.svg',
        activeIconUrl: 'assets/images/custom/sidebar/pacs-active.svg',
        link: '/pacs',
        width: 18
    },
    {
        id: MenuItemsEnum.UpdateCompany,
        label: 'Company',
        iconUrl: 'assets/images/custom/sidebar/company.svg',
        activeIconUrl: 'assets/images/custom/sidebar/company-active.svg',
        link: '/company',
        width: 18
    }
];