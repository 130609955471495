import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateWithoutTimezone'
})
export class DateWithoutTimezonePipe implements PipeTransform {
  constructor(private readonly _datePipe: DatePipe){

  }
  transform(date: string, format?: string): string {
    if(!date) {
      return '';
    }
    const dateObj = date.slice(0, 10);
    return this._datePipe.transform(dateObj, format)
  }

}
