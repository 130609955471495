import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import {
  ExtendedUser,
  User,
  UserActivityLog,
  UserCompany,
} from "../../pages/users/user.model";
import { DataService } from "src/app/core/services/data.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private readonly _dataService: DataService) { }

  getCompanyUsers(): Observable<User[]> {
    return this._dataService.get("api/v1/users/company");
  }

  getLoggedInUser(): Observable<User> {
    return this._dataService.get("api/v1/auth/currentUser");
  }

  getUserCompanies(userId: string): Observable<UserCompany[]> {
    return this._dataService.get(`api/v1/users/${userId}/companies`);
  }

  getUserById(userId: string): Observable<ExtendedUser> {
    return this._dataService.get(`api/v1/users/${userId}`);
  }

  insertUser(userRequest: Partial<ExtendedUser>) {
    return this._dataService.post("api/v1/users", userRequest);
  }

  updateUser(userId: string, userRequest: Partial<ExtendedUser>) {
    return this._dataService.patch(`api/v1/users/${userId}`, userRequest);
  }

  deleteUser(userId: string) {
    return this._dataService.delete(`api/v1/users/${userId}`);
  }

  deactivateUser(userId: string, statusId: number) {
    return this._dataService.patch(`api/v1/users/${userId}`, {
      status: statusId,
    });
  }

  resendInvite(userId: string) {
    return this._dataService.post(
      `api/v1/auth/user/${userId}/resend-invite`,
      null
    );
  }

  getUserActivityLogs(
    userId: string,
    deviceId: string
  ): Observable<UserActivityLog[]> {
    return this._dataService.get(`api/v1/auth/activities/${userId}`).pipe(
      map((logs) =>
        logs.map((log: any) => ({
          ...log,
          device: this.getDeviceAndBrowserInfo(log.userAgent),
          deviceId: deviceId,
        }))
      )
    );
  }

  private getDeviceAndBrowserInfo(userAgent: string): string {
    if (!userAgent) {
      return '';
    }
    return `${this.extractDeviceFromuserAgent(userAgent)} / ${this.extractBrowerInfoFromUserAgent(userAgent)}`;
  }

  private extractBrowerInfoFromUserAgent(userAgent: string) {
    if ((userAgent.indexOf("Opera") || userAgent.indexOf("OPR")) != -1) {
      return "Opera";
    } else if (userAgent.indexOf("Edg") != -1) {
      return "Edge";
    } else if (userAgent.indexOf("Chrome") != -1) {
      return "Chrome";
    } else if (userAgent.indexOf("Safari") != -1) {
      return "Safari";
    } else if (userAgent.indexOf("Firefox") != -1) {
      return "Firefox";
    } else {
      return "Unknown";
    }
  }

  extractDeviceFromuserAgent(userAgent: string): string {
    if (userAgent.includes("Macintosh")) {
      return "Macintosh";
    } else if (userAgent.includes("iPhone")) {
      return "iPhone";
    } else if (userAgent.includes("Android")) {
      return "Android";
    } else if (userAgent.includes("Windows")) {
      return "Windows";
    } else {
      return "Other";
    }
  }
}
