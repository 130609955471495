import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { Observable, of } from 'rxjs';
import { AuthToken } from '../models/auth.models';
import { Utils } from './utils';
import { AvailableApplication } from '../models/environment.interface';
import { DataService } from './data.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    constructor(
        private readonly _localStorageService: LocalStorageService,
        private readonly _dataService: DataService) {
    }

    getNewAccessToken(refreshToken: string): Observable<AuthToken> {
        return this._dataService.post('api/v1/auth/refresh-token', { refreshToken });
    }

    getAuthTokenForCompany(userId: string, companyId: string): Observable<AuthToken> {
        return this._dataService.post('api/v1/auth/company_auth', {
            userId,
            companyId
        });
    }

    hideOrPinCompany(request: any): Observable<void> {
        return this._dataService.post('api/v1/users/pin-hide-company', request);
    }

    logoutAllApps(): Observable<void> {
        return this._dataService.post('api/v1/auth/logout', null);
    }

    deviceSessionCheck(): Observable<AuthToken> {
        if (!this._localStorageService.machineId) {
            return of(null);
        }
        return this._dataService.get(`api/v1/auth/check-session/${this._localStorageService.machineId}`);
    }

    get authToken(): string {
        if (!this._localStorageService.authorizationData) {
            return null;
        }
        return this._localStorageService.authorizationData.token;
    }

    get refreshToken(): string {
        if (!this._localStorageService.authorizationData) {
            return null;
        }
        return this._localStorageService.authorizationData.refreshToken;
    }

    logout(loggedOutFromAllApps: boolean = false): void {
        if (this._localStorageService.authorizationData) {
            this._localStorageService.authorizationData = null;
        }
        if (loggedOutFromAllApps) {
            window.location.href = `${environment.loginUrl}/logout`;
            return;
        }
        this.redirectToLogin(0);
    }

    redirectToLogin(timeoutSecs: number = 1000) {
        setTimeout(() => {
            window.location.href = `${environment.loginUrl}?app=${AvailableApplication.Admin}`;
        }, timeoutSecs);
    }

    get apiBaseUrl(): string {
        return environment.apiBaseUrl;
    }

    persistAuthData = (token: AuthToken): void => {
        let jwtPayload = Utils.parseJwt(token.accessToken);
        const authData = {
            token: token.accessToken,
            refreshToken: token.refreshToken,
            expiresAt: Utils.getTokenExpireDate(jwtPayload.exp)
        };

        this._localStorageService.authorizationData = authData;
    }

    appendEncryptedAuthInfo(url: string, patientId: string = null): string {
        const queryParams = [
            `q=${encodeURIComponent(this._localStorageService.authorizationDataEncrypted)}`
        ];
        if (patientId) {
            queryParams.push(`id=${encodeURIComponent(patientId)}`);
        }
        if (this._localStorageService.machineId) {
            queryParams.push(`d=${encodeURIComponent(this._localStorageService.machineId)}`);
        }
        return `${url}/app-redirect?${queryParams.join('&')}`;
    }
}

export interface AuthData {
    token: string;
    refreshToken: string;
    expiresAt: Date;
}

