import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {
    transform(value: boolean | string | number): string {
        switch (value) {
            case true:
            case "true":
            case 1:
            case "1":
                return "Yes";
            default:
                return "No";
        }
    }
}