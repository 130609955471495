import { UserPermission } from "src/app/core/models/permission.model";
import { UserProduct } from "src/app/core/models/product.model";
import { UserLocation } from "../company-locations/company-location.model";

export class User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    designation: string;
    title: string;
    status: UserStatus;
    type: UserType;
    globalAdmin: boolean;
    isOwner: boolean;
    npi: string;
    companyName: string;
    companyId: string;
    constructor(userType: UserType = UserType.BillingPhysician, title: string = '') {
        this.type = userType;
        this.title = title;
    }
}

export interface UserCompany {
    userId: string;
    isPinned: boolean;
    isHidden: boolean;
    companyId: string;
    companyName: string;
    company: BasicCompanyInfo;
    userActivities: UserActivityLog;
}

export interface BasicCompanyInfo extends EditableCompanyInfo {
    id: string;
    isPacEnabled: string;
    pacPassword: string;
    pacUsername: string;
    subscriptionStatus: string;
}

export interface EditableCompanyInfo {
    address: string;
    city: string;
    fax: string;
    name: string;
    phone: string;
    state: string;
    zipCode: string;
}

export class ExtendedUser extends User {
    sendEmail: boolean;
    userLocations: UserLocation[];
    userPermissions: UserPermission[];
    userProducts: UserProduct[];
    constructor() {
        super();
        this.sendEmail = false;
        this.globalAdmin = false;
        this.userLocations = [];
        this.userPermissions = [];
        this.userProducts = [];
    }
}

export interface UserActivityLog {
    geoLocation: string;
    latitude: number;
    longitude: number;
    ipAddress: string;
    userId: string;
    user_deviceId: string;
    country: string;
    device: string
    createdAt: string;
    status: number;
}

export enum UserStatus {
    Active = 1,
    Pending,
    Suspended
}

export enum UserType {
    BillingPhysician = 1,
    Nurse,
    MedicalAssistent,
    NonMedicalPersonal,
    Biller
}

export enum UserWizardSteps {
    BasicInfo = 1,
    Products,
    Locations,
    Summary
}