import { UserType } from "src/app/pages/users/user.model";

export class Utils {
  static parseJwt(token: string): any {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  static getTokenExpireDate(exp: number) {
    let expiresDate = new Date(0);
    expiresDate.setUTCMilliseconds(exp * 1000);

    return expiresDate;
  }

  static getEnumerationItemsFromUserTypeEnum() {
    return Object.keys(UserType).filter(t => isNaN(Number(t)))
      .map(key => ({ value: UserType[key], displayName: this.splitCamelCase(key) }))
  }

  static splitCamelCase(value: string | undefined): string {
    return value?.replace(/([a-z])([A-Z])/g, '$1 $2') ?? '';
  }

  static MinsToHm = (minsFromMidNight: number): string => {
    minsFromMidNight = Number(minsFromMidNight);
    const h = Math.floor(minsFromMidNight / 60);
    const m = minsFromMidNight % 60;
    const formattedHours = h.toString().length === 1
      ? '0' + h.toString()
      : h.toString();
    const formattedMins = m.toString().length === 1
      ? '0' + m.toString()
      : m.toString();
    return `${formattedHours}:${formattedMins}`;
  }

  static HmToMins = (formattedTime: string): number => {
    const hmString = formattedTime.split(':');
    if (hmString.length !== 2) {
      return 0;
    }
    const hrs = Number(hmString[0]);
    const mins = Number(hmString[1]);
    return (hrs * 60) + mins;
  }

  static getTimeInMinutesFromMidnight(date: Date): number {
    if (!(date instanceof Date)) {
      return 0;
    }

    const hours = date.getHours();
    const minutes = date.getMinutes();

    return hours * 60 + minutes;
  }

  static getEnumerationItemsFromDayOfWeekEnum() {
    return Object.keys(DayOfWeek)
      .map(key => ({ value: DayOfWeek[key], displayName: key }))
  }
}

export enum DayOfWeek {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday"
}


export enum MenuItemsEnum{
  Users = 1,
  Locations,
  Products,
  Portal,
  PACS,
  UpdateCompany
}
