import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { PagetitleComponent } from './components/pagetitle/pagetitle.component';
import { ToastrModule } from 'ngx-toastr';
import { SafeUrlPipe } from '../core/pipes/safe-url.pipe';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout';
import { DateWithoutTimezonePipe } from '../core/pipes/date-without-timezone.pipe';
import { YesNoPipe } from '../core/pipes/yes-no.pipe';

@NgModule({
  declarations: [LoaderComponent, PagetitleComponent, SafeUrlPipe, SessionTimeoutComponent, DateWithoutTimezonePipe, YesNoPipe],
  imports: [
    CommonModule,
    ToastrModule.forRoot({ timeOut: 3000 }),
  ],
  exports: [LoaderComponent, PagetitleComponent, SafeUrlPipe, SessionTimeoutComponent, DateWithoutTimezonePipe, YesNoPipe]
})

export class SharedModule { }
