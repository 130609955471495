import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationContextService } from 'src/app/core/services/application-context.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './app-redirect.component.html',
  styleUrls: ['./app-redirect.component.scss']
})
export class AppRedirectComponent implements OnInit {
  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthenticationService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _appContextService: ApplicationContextService) {
  }


  ngOnInit() {
    this._localStorageService.authorizationData = null;
    const encryptedAuthInfo = decodeURIComponent(this._activatedRoute.snapshot.queryParams.q);
    const machineId = decodeURIComponent(this._activatedRoute.snapshot.queryParams.d);
    if (!encryptedAuthInfo || !machineId) {
      this._authService.redirectToLogin();
      return;
    }
    this._localStorageService.authorizationDataEncrypted = encryptedAuthInfo;
    this._localStorageService.machineId = machineId;
    this._appContextService.context.subscribe(context => {
      this._router.navigateByUrl('/');
    })
  }
}