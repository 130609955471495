import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { AppRedirectComponent } from './pages/app-redirect/app-redirect.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { UserCompaniesListComponent } from './pages/user-companies-list/user-companies-list.component';

const routes: Routes = [
  { path: 'app-redirect', component: AppRedirectComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'select-company', component: UserCompaniesListComponent, canActivate: [AuthGuard] },
  { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
